<template>
  <div>
    <footer class="bg-primary-500 py-6 text-white">
      <div class="container">
        <div class="flex flex-col items-center justify-between gap-3 px-3 sm:flex-row sm:gap-0">
          <a target="_blank" href="/terms-condition">{{ $t('general.footer.t&c') }}</a>
          <div class="flex gap-2">
            <p>{{ $t('general.footer.poweredBy') }}</p>
            <a href="https://year13.com.au" target="_blank">
              <img :alt="`${$t('general.footer.poweredBy')} year13`" class="mt-[-3px] w-[80px]" src="@/assets/images/logos/year13-logo.svg" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
